import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Handstand Holds & Hanging L Holds`}</p>
    <p>{`10:00 EMOM:`}</p>
    <p>{`Odd minutes: :30 HS Hold`}</p>
    <p>{`Even minutes: :30 Hanging L Hold`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`500M Row`}</p>
    <p>{`25 Pushups`}</p>
    <p>{`250M Ski Erg`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      